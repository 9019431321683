import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import Layout from "components/Layout";
import Link from "components/Link";
import Seo from "components/Seo";
import { useEffect, useState } from "react";
import theme from "theme";

import style from "./style.module.scss";

const ErrorPage = () => {
  const [enable, setEnable] = useState(false);

  useEffect(() => {
    setTimeout(() => setEnable(true), 100);
  }, []);

  if (!enable) return null;
  return (
    <Box className={style.errorPage_container}>
      <p className={style.errorPage_container_title}>
        Aktualnie trwają prace konserwacyjne
      </p>
      <p className={style.errorPage_container_text}>
        Spróbuj ponownie za jakiś czas
      </p>

      <Link
        color="secondary"
        underline="hover"
        href="https://www.expander.pl/"
        target="_blank"
      >
        https://www.expander.pl
      </Link>
    </Box>
  );
};

export default ErrorPage;
