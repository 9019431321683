import React, { memo, useEffect, useRef, useState } from "react";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
  Hidden,
  useMediaQuery,
} from "@mui/material";

import PropTypes from "prop-types";

import { useStoreActions, useStoreState } from "easy-peasy";

import theme from "theme";

import { Close } from "@mui/icons-material";

import RealestatesServices from "services/RealestatesServices";
import CheckGroup from "./components/CheckGroup";
import ControlButtons from "./components/ControlButtons";
import { toast } from "react-toastify";
import InvestmentsServices from "services/InvestmentsServices";
import useFavorites from "hooks/useFavorites";

const DialogTitleComponent = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

DialogTitleComponent.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

const ComparisonModal = memo(() => {
  const { removeFromFavorites, getAllFavorites } = useFavorites();

  const open = useStoreState((state) => state?.modals?.comparisonModal);
  const openComparisonModal = useStoreActions(
    (state) => state.modals.openComparisonModal
  );

  const [comparions, setComparions] = useState([]);
  const [loader, setLoader] = useState(true);

  const [allGroup, setAllGroup] = useState([]);

  const convertFavoritesObjToArray = (favorites) => {
    const arr = [];
    for (const [key, value] of Object.entries(favorites)) {
      arr.push({ uuid: key, ...value });
    }
    return arr;
  };

  const refresh = async () => {
    setLoader(true);
    setComparions([]);
    setAllGroup([]);

    let convertFavorities = convertFavoritesObjToArray(getAllFavorites);

    if (convertFavorities?.length === 0) {
      setLoader(false);
      return;
    }

    await InvestmentsServices.getInvestmentsInfo(
      999,
      convertFavorities?.map((el) => el.uuid)
    ).then((res) => {
      if (res?.data?.data?.length > 0) {
        convertFavorities = convertFavorities?.map((el) => {
          const findEl = res?.data?.data?.find(
            (elFind) => elFind.uuid === el.uuid
          );
          if (findEl) {
            let name = findEl?.name || "";
            if (name === "rynek wtórny") name = "Rynek wtórny";
            if (name === "") name = `${findEl?.city}, ${findEl?.district}`;

            return {
              ...el,
              image: findEl?.investment_pictures
                ? findEl?.investment_pictures
                : null,
              name: name,
              area_from: findEl?.area_from,
              area_to: findEl?.area_to,
              rooms_from: findEl?.rooms_from,
              rooms_to: findEl?.rooms_to,
              price_from: findEl?.price_from,
              cityToRedirect: findEl?.city,
              districtToRedirect: findEl?.district,
            };
          } else return el;
        });
      }
    });

    const allRealestates = convertFavorities?.map((el) => el.childs);
    const spreadArray = [...[].concat(...allRealestates)];

    if (spreadArray.length > 0) {
      await RealestatesServices.getRealestatesInfo(999, spreadArray).then(
        async (res) => {
          if (res?.data?.data?.length > 0) {
            const responseElements = res?.data?.data;
            convertFavorities?.map((el) => {
              if (!el.childs) return el;
              const copyChilds = [...el.childs];
              el.childs = [];

              copyChilds?.forEach((child) => {
                const findEl = responseElements.find(
                  (elFind) => elFind?.uuid === child
                );
                if (findEl) {
                  el?.childs.push(findEl);
                }
              });

              return el;
            });
          }
        }
      );
    }

    const findSecondaryMarket = convertFavorities.findIndex(
      (el) => el.uuid === process.env.SECONDARY_MARKET_UUID
    );

    if (findSecondaryMarket !== -1) {
      const smSplice = convertFavorities.splice(findSecondaryMarket, 1);
      convertFavorities.push(smSplice[0]);
    }

    setComparions(convertFavorities);
    setLoader(false);
  };

  useEffect(() => {
    if (!open) return;
    refresh();
  }, [open, getAllFavorites]);

  const handleCheckedItems = (el) => {
    setAllGroup((prev) => {
      const groups = [...prev];
      groups[el?.group] = el;
      return groups;
    });
  };

  const deleteItem = async (type, investment_uuid, uuid) => {
    return await removeFromFavorites(type, investment_uuid, uuid);
  };

  const deleteSingleItem = async (type, investment_uuid, uuid) => {
    setLoader(true);
    await deleteItem(type, investment_uuid, uuid);
  };

  const handleButtonsActions = async ({ value }) => {
    switch (value) {
      case "remove":
        let count = 0;

        const allInvestments = allGroup
          ?.map((el) => el.parent)
          .filter(
            (el) =>
              el?.uuid &&
              el?.uuid !== process.env.SECONDARY_MARKET_UUID &&
              el?.active
          )
          ?.map((el) => el.uuid);

        if (allInvestments?.length > 0) count = 1;

        const allRealestates = [];
        allGroup?.forEach(({ childs }) => {
          if (childs?.length > 0) {
            childs?.forEach((child) => {
              allRealestates.push({
                investment_uuid: child?.investment_uuid,
                uuid: child?.uuid,
              });
            });
          }
        });

        if (allRealestates?.length > 0) count += 1;

        if (count === 0) {
          return toast.error(
            "Musisz zaznaczyć minimum jedną inwestycję lub lokal"
          );
        }

        setLoader(true);

        allRealestates?.forEach(async ({ investment_uuid, uuid }) => {
          await deleteItem("realestate", investment_uuid, uuid);
        });

        allInvestments?.forEach(async (investment_uuid) => {
          await deleteItem("investment", investment_uuid);
        });

        break;

      default:
        break;
    }
  };

  const matches = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  return (
    <Dialog
      id="ComparisonModal"
      open={open}
      aria-labelledby="user-dialog-title"
      aria-describedby="user-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: matches ? "white" : "#f8f8f8",
        },
      }}
    >
      <DialogTitleComponent
        sx={{
          borderBottom: `1px solid ${theme.palette.grey[300]}`,
          display: "flex",
          alignItems: "center",

          [theme.breakpoints.down("md")]: {
            flexDirection: "column",
            px: 0,
            backgroundColor: "White",
          },
        }}
        id="user-dialog-title"
        onClose={() => openComparisonModal(false)}
      >
        <Typography>Ulubione</Typography>
        <Box
          ml={8}
          sx={{
            [theme.breakpoints.down("md")]: {
              ml: 0,
              // px: 2,
            },
          }}
        >
          <Hidden mdDown>
            <ControlButtons handleButtonsActions={handleButtonsActions} />
          </Hidden>
        </Box>
      </DialogTitleComponent>
      <DialogContent>
        <Box
          minWidth="60vw"
          sx={{
            minHeight: "60vh",
          }}
        >
          {loader && (
            <Box position="absolute" left="45%" top="50%">
              <CircularProgress />
            </Box>
          )}{" "}
          {!loader && (
            <>
              <Box display="flex">
                <Box mb={4} width="100%">
                  {comparions?.length > 0 &&
                    comparions?.map((el, index) => {
                      return (
                        <CheckGroup
                          key={el.uuid}
                          item={el}
                          handleCheckedItems={handleCheckedItems}
                          index={index}
                          deleteSingleItem={deleteSingleItem}
                        />
                      );
                    })}
                </Box>
              </Box>

              {comparions?.length === 0 && (
                <Box textAlign="center">
                  <Typography variant="h2" sx={{ color: "black !important" }}>
                    Lista ulubionych jest pusta
                  </Typography>
                </Box>
              )}
            </>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
});
ComparisonModal.propTypes = {
  name: PropTypes.string,
};
ComparisonModal.defaultProps = {
  name: `modal_${Math.random()}`,
};

ComparisonModal.displayName = "ComparisonModal";

export default ComparisonModal;
