import React from "react";
import { Typography } from "@mui/material";
import {
  markersSecondaryMarketFlat,
  markersSecondaryMarketHouse,
} from "./dictionary-markers";

import samochodIcon from "assets/icons/samochod1.png";
import medalIcon from "assets/icons/medal.png";

export const parseMeters = (value) => (
  <Typography component="span">
    <strong> {value}</strong> {` m`}
    <sup>2</sup>
  </Typography>
);

export const regexStringToNumberPrice = (input) => {
  4;
  if (!input || input === undefined) return "0.00";
  const inputReplice = input
    .toString()
    .replace(/[,]/g, ".")
    .replace(/[^0-9.]/g, "");
  const parts = inputReplice.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");

  if (parts[1]?.length === 1) {
    parts[1] = parts[1] + "0";
  }
  if (parts[1]?.length > 2) {
    parts[1] = parts[1].slice(0, 2);
  }

  parts.length = 2;

  if (parts[1] === "00") parts[1] = null;

  if (!parts[1]) return parts[0];

  return parts.join(".");
};

export const cleaningQuery = (obj) => {
  let o = Object.fromEntries(
    Object.entries(obj).filter(([_, v]) => v != null && v.length !== 0)
  );

  return o;
};

export const handleNamesRooms = (c) => {
  const count = c * 1;
  if (count === 1) return "pokój";
  if (count > 1 && count < 5) return "pokoje";
  else return "pokoi";
};

export const getComparisonsFunction = async (items) => {
  const obj = {};
  await items.forEach((el) => {
    if (!obj[el?.investment?.uuid]) {
      const uuidInvestment = el?.investment?.uuid;

      obj[uuidInvestment] = { name: el?.investment?.name };
      if (el?.property) {
        obj[uuidInvestment].id = null;
        obj[uuidInvestment].childs = [
          {
            uuid: el?.property?.uuid,
            name: el?.property?.name,
            id: el?.id,
          },
        ];
      } else {
        obj[uuidInvestment].id = el.id;
        obj[uuidInvestment].childs = [];
      }
    } else {
      if (!el?.property) {
        obj[el?.investment?.uuid].id = el?.id;
      }
      if (el?.property) {
        obj[el?.investment?.uuid].childs = [
          ...obj[el?.investment?.uuid].childs,
          {
            uuid: el?.property?.uuid,
            name: el?.property?.name,
            id: el?.id,
          },
        ];
      }
    }
  });
  return obj;
};

function repliceValue(text) {
  let newText = text.toString().toLowerCase();

  newText = newText.replace("ę", "e");
  newText = newText.replace("ó", "o");
  newText = newText.replace("ą", "a");
  newText = newText.replace("ś", "s");
  newText = newText.replace("ł", "l");
  newText = newText.replace("ż", "z");
  newText = newText.replace("ź", "z");
  newText = newText.replace("ć", "c");
  newText = newText.replace("ń", "n");
  return newText;
}

export const getCityAndDistrict = (city, district) => {
  if (!city && !district) return null;
  if (!district) return repliceValue(city);
  return `${repliceValue(city)}-${repliceValue(district)}`;
};

export function validateEmail(email) {
  const re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export const getSecondaryMarketMarker = (data, filters) => {
  if (!data || !filters || !filters[data?.realestate_type_name]) {
    return null;
  }
  const dictionary =
    data?.realestate_type_name === "Flat"
      ? markersSecondaryMarketFlat
      : markersSecondaryMarketHouse;

  const arr = [];
  if (data?.building_type_id) {
    const findIdBuilding = filters[
      data?.realestate_type_name
    ]?.BuildingType?.indexOf(data?.building_type_id * 1);

    const find = dictionary.find(
      ({ type, value }) =>
        type === "building_type_id" && value === findIdBuilding
    );
    if (find) arr.push(find);
  }
  if (data?.building_state_id) {
    const findIdConstruction = filters[
      data?.realestate_type_name
    ]?.ConstructionStatusType?.indexOf(data?.building_state_id * 1);

    const findConstruction = dictionary.find(
      ({ type, value }) =>
        type === "building_state_id" && value === findIdConstruction
    );

    if (findConstruction) arr.push(findConstruction);
  }
  if (data?.garret_type_id) {
    const findIdGarret = filters[
      data?.realestate_type_name
    ]?.GarretType?.indexOf(data?.garret_type_id * 1);

    const findGarret = dictionary.find(
      ({ type, value }) => type === "garret_type_id" && value === findIdGarret
    );

    if (findGarret) arr.push(findGarret);
  }
  if (data?.heating_type_id) {
    const findIdHeating = filters[
      data?.realestate_type_name
    ]?.HeatingType?.indexOf(data?.heating_type_id * 1);

    const findHeating = dictionary.find(
      ({ type, value }) => type === "heating_type_id" && value === findIdHeating
    );

    if (findHeating) arr.push(findHeating);
  }
  if (data?.extras) {
    data?.extras?.map((el) => {
      const findIdExtras = filters[
        data?.realestate_type_name
      ]?.ExtraType?.indexOf(el?.id * 1);
      const findExtras = dictionary.find(
        ({ type, value }) => type === "extras" && value === findIdExtras
      );

      if (findExtras) arr.push(findExtras);
    });
  }
  if (data?.securities) {
    data?.securities?.map((el) => {
      const findIdSecurities = filters[
        data?.realestate_type_name
      ]?.SecurityType?.indexOf(el?.id * 1);
      const findSecurities = dictionary.find(
        ({ type, value }) => type === "securities" && value === findIdSecurities
      );

      if (findSecurities) arr.push(findSecurities);
    });
  }
  if (data?.media) {
    data?.media?.map((el) => {
      const findIdMedia = filters[
        data?.realestate_type_name
      ]?.MediaType?.indexOf(el?.id * 1);
      const findMedia = dictionary.find(
        ({ type, value }) => type === "media" && value === findIdMedia
      );

      if (findMedia) arr.push(findMedia);
    });
  }
  if (data?.vicinities) {
    data?.vicinities?.map((el) => {
      const findIdVicinities = filters[
        data?.realestate_type_name
      ]?.VicinityType?.indexOf(el?.id * 1);
      const findVicinities = dictionary.find(
        ({ type, value }) => type === "vicinities" && value === findIdVicinities
      );

      if (findVicinities) arr.push(findVicinities);
    });
  }
  const sliceArr = arr
    .sort((a, b) => (a?.order > b?.order ? 1 : -1))
    .slice(0, 1);

  const returnElement = sliceArr[0];
  if (!returnElement) return null;
  if (
    returnElement.first_line === "Garaż" ||
    returnElement.first_line === "Z garażem"
  ) {
    returnElement.icon = samochodIcon;
    returnElement.iconWidth = 50;
    returnElement.iconHeight = 28;
  } else {
    returnElement.icon = medalIcon;
    returnElement.iconWidth = 28;
    returnElement.iconHeight = 28;
  }
  return returnElement;
};

export function validatePassword(pass) {
  const regex = new RegExp("^(?=.*[A-Z])+(?=.*[a-z])(?=.*[0-9]).{12,128}");
  return regex.test(pass);
}
export function validateReqExName(name) {
  const regex = new RegExp(
    "^[A-Za-z-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]+(([',. -][A-Za-z-zżźćńółęąśŻŹĆĄŚĘŁÓŃ])?[A-Za-z-zżźćńółęąśŻŹĆĄŚĘŁÓŃ]*)*$"
  );
  return regex.test(name);
}
export function validatePhone(phone) {
  const regex = new RegExp("^[\\d\\s+()-]{9,17}$");
  return regex.test(phone);
}

export const convertStringName = (name) => {
  if (!name || typeof name !== "string" || name?.length === 0) return ``;
  if (name?.slice(-1) === " ") name = name?.slice(0, name?.length - 1);
  const firstName = name?.split(" ")?.slice(0, -1)?.join(" ");
  const lastName = name?.split(" ")?.slice(-1)?.join(" ");

  return `${lastName} ${firstName}`;
};
