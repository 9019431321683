import axios from "utils/axios";

const DictionaryServices = {
  getDictionary: async () => {
    return await axios
      .get(`/dictionary-index`)
      .then((res) => {
        return res;
      })
      .catch((err) => err);
  },
};

export default DictionaryServices;
