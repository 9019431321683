import { useStoreActions, useStoreState } from "easy-peasy";
import { useMemo } from "react";

// {
//   ["uuidInvestment"]: {
//     active: true / false,
//     childs: [uuid],
//   },
// };

const useFavorites = () => {
  const favorites = useStoreState((state) => state.favorites.favorites);
  const addFavorite = useStoreActions(
    (actions) => actions.favorites.addFavorite
  );
  const removeFavorite = useStoreActions(
    (actions) => actions.favorites.removeFavorite
  );

  const addInvestmentToFavorite = async (uuidInvestment) => {
    try {
      await addFavorite({
        type: "investment",
        uuidInvestment,
      });

      return { success: true };
    } catch (error) {
      console.log({ error });
      return { success: false, message: error?.message };
    }
  };

  const removeInvestmentFromFavorite = async (uuidInvestment) => {
    try {
      await removeFavorite({ type: "investment", uuidInvestment });
      return { success: true };
    } catch (error) {
      console.log({ error });
      return { success: false, message: error?.message };
    }
  };

  const addRealestateToFavorite = async (uuidInvestment, uuidRealestate) => {
    try {
      await addFavorite({
        type: "realestate",
        uuidInvestment,
        uuidRealestate,
      });
      return { success: true };
    } catch (error) {
      console.log({ error });
      return { success: false, message: error?.message };
    }
  };

  const removeRealestateFromFavorite = async (
    uuidInvestment,
    uuidRealestate
  ) => {
    try {
      await removeFavorite({
        type: "realestate",
        uuidInvestment,
        uuidRealestate,
      });
      return { success: true };
    } catch (error) {
      console.log({ error });
      return { success: false, message: error?.message };
    }
  };

  const addToFavorites = async (
    type = "investment",
    uuidInvestment,
    uuidRealestate
  ) => {
    try {
      if (type === "investment") {
        if (!uuidInvestment) return { success: false, message: "Brak uuid" };
        const addInvesmentRes = await addInvestmentToFavorite(uuidInvestment);
        if (!addInvesmentRes.success) return addInvesmentRes;
      } else if (type === "realestate") {
        if (!uuidInvestment || !uuidRealestate) {
          return {
            success: false,
            message: `Brak uuid ${uuidInvestment ? "inwestycji" : "lokalu"}`,
          };
        }
        const addRealestateRes = await addRealestateToFavorite(
          uuidInvestment,
          uuidRealestate
        );
        if (!addRealestateRes.success) return addRealestateRes;
      }
      return { success: true };
    } catch (error) {
      console.log(error);
      return { success: false };
    }
  };

  const removeFromFavorites = async (
    type = "investment",
    uuidInvestment,
    uuidRealestate
  ) => {
    try {
 
      if (type === "investment") {
        if (!uuidInvestment) return { success: false, message: "Brak uuid" };
        const removeInvesmentRes = await removeInvestmentFromFavorite(
          uuidInvestment
        );
        if (!removeInvesmentRes.success) return removeInvesmentRes;
      } else if (type === "realestate") {
        if (!uuidInvestment || !uuidRealestate) {
          return {
            success: false,
            message: `Brak uuid ${uuidInvestment ? "inwestycji" : "lokalu"}`,
          };
        }
        const removeRealestateRes = await removeRealestateFromFavorite(
          uuidInvestment,
          uuidRealestate
        );
        if (!removeRealestateRes.success) return removeRealestateRes;
      }

      return { success: true };
    } catch (error) {
      console.log(error);
      return { success: false };
    }
  };

  const countFavorites = useMemo(() => {
    let count = 0;
    for (const key in favorites) {
      if (favorites[key].active) {
        count += 1;
      }
      if (favorites[key].childs) {
        count += favorites[key].childs.length;
      }
    }

    return count;
  }, [favorites]);

  const getAllFavorites = favorites;

  return {
    addToFavorites,
    removeFromFavorites,

    getAllFavorites,
    countFavorites,
  };
};

export default useFavorites;
