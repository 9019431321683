import axios from "utils/axios";

const InvestmentsServices = {
  // Do poprawy limit jak BO zostanie poprawione (musi być -1 do czasu poprawy)
  getInvesments: async (totalItems = 9999, page = 1, paramsInvestment = {}) => {
    return await axios
      .get(`/investment-index`, {
        params: {
          limit: totalItems - 1,
          page,
          with_name_as_title_for_secondary_market: 1,
          with_existing_properties: 1,

          ...paramsInvestment,
          price_from: paramsInvestment?.price_from
            ? paramsInvestment?.price_from * 1000
            : null,
          price_to: paramsInvestment?.price_to
            ? paramsInvestment?.price_to * 1000
            : null,
          pricemkw_from: paramsInvestment?.pricemkw_from
            ? paramsInvestment?.pricemkw_from * 1000
            : null,
          pricemkw_to: paramsInvestment?.pricemkw_to
            ? paramsInvestment?.pricemkw_to * 1000
            : null,
        },
      })
      .then((res) => {
        return res;
      });
  },
  getInvesment: async (investment_uuid) => {
    return await axios
      .get(`/investment-view/${investment_uuid}`)
      .then((res) => {
        return res;
      });
  },

  getInvesmentsWithFilters: async (limit = 1, query) => {
    return await axios
      .get(`investment-index`, {
        params: {
          limit,
          with_existing_properties: 1,
          with_name_as_title_for_secondary_market: 1,
          ...query,
        },
      })
      .then((res) => res);
  },

  getInvestmentsInfo: async (limit = 999, investments) => {
    return await axios
      .get(`investment-index`, {
        params: {
          limit,
          with_name_as_title_for_secondary_market: 1,
          uuid: investments,
        },
      })
      .then((res) => res);
  },
};

export default InvestmentsServices;
