import * as React from "react";
import {
  CircularProgress,
  Checkbox,
  Box,
  Typography,
  FormControlLabel,
  Hidden,
} from "@mui/material";
import Image from "next/image";
import Link from "components/Link";
import PlanModal from "modals/PlanModal";
import Button from "components/Button";
import theme from "theme";
import { useStoreActions } from "easy-peasy";
import { useRouter } from "next/router";
import { getCityAndDistrict, regexStringToNumberPrice } from "utils/functions";
import { PlanSVG } from "assets/svg";

const TextBox = ({ label, value }) => {
  if (!value) return null;
  else
    return (
      <Typography pr={3}>
        <Typography component="span" fontWeight="bold">
          {label}{" "}
        </Typography>
        {value}
      </Typography>
    );
};

const DisplayImage = ({
  image,
  width = 60,
  height = 80,
  openSiglePlanModal,
  investment = false,
  investmentMobile = false,
}) => {
  const [loaded, setLoaded] = React.useState(false);
  const [onErrorImage, setOnErrorImage] = React.useState(false);

  if (onErrorImage) return null;
  return (
    <Box
      position="relative"
      style={{
        width: investment ? 80 : width,
        height: investment ? 60 : height,
      }}
    >
      {!loaded && (
        <Box
          position="absolute"
          top="50%"
          left="50%"
          sx={{ transform: "translate(-50%,-50%)" }}
        >
          <CircularProgress />
        </Box>
      )}
      <Box
        onClick={(e) => {
          e.preventDefault();
          openSiglePlanModal({ image, investment, investmentMobile });
        }}
        sx={{ cursor: "pointer" }}
        display={!loaded && "hidden"}
      >
        <Image
          unoptimized
          width={investment ? 80 : width}
          height={investment ? 60 : height}
          // layout="fixed"
          layout="fill"
          objectFit="cover"
          quality={25}
          src={`${
            investment
              ? process.env.BASE_IMG_URL
              : investmentMobile
              ? process.env.BASE_IMG_URL
              : process.env.BASE_PLAN_URL
          }${
            investment ? "h30_" : investmentMobile ? "h30_" : "bigtn_"
          }${image}`}
          onLoadingComplete={() => setLoaded(true)}
          onError={(e) => setOnErrorImage(true)}
        />
      </Box>
    </Box>
  );
};

const CheckGroup = ({
  item,
  handleCheckedItems,
  index,
  deleteSingleItem = () => {},
}) => {
  const [checkedGroup, setCheckedGroup] = React.useState(null);
  const [checked, setChecked] = React.useState([]);

  const openComparisonModal = useStoreActions(
    (state) => state.modals.openComparisonModal
  );

  const openSiglePlanModal = useStoreActions(
    (state) => state.modals.openSiglePlanModal
  );

  React.useEffect(() => {
    handleChecked();
  }, [checkedGroup, checked]);

  const handleChange1 = (event) => {
    if (event.target.checked) {
      setChecked(item?.childs);
      setCheckedGroup({
        name: item?.name,
        uuid: item?.uuid,
        active: item?.active,
      });
    } else {
      setChecked([]);
      setCheckedGroup(null);
    }
  };

  const handleChange2 = (event, child) => {
    if (event.target.checked) {
      setChecked((prev) => {
        if (prev?.length + 1 === item?.childs?.length) {
          setCheckedGroup({
            name: item?.name,
            uuid: item?.uuid,
            id_comparison: item?.id_comparison,
          });
        } else {
          setCheckedGroup(null);
        }
        return [...prev, child];
      });
    } else {
      const newChecks = checked.filter((el) => el?.uuid !== child?.uuid);

      setCheckedGroup(null);
      setChecked(newChecks);
    }
  };
  const handleChecked = () => {
    handleCheckedItems({ group: index, parent: checkedGroup, childs: checked });
  };

  const checkCheckbox = (uuid) => {
    const check = checked?.find((el) => el?.uuid === uuid);
    if (!check) return false;
    else return true;
  };

  const children = (
    <Box
      mt={1}
      ml={3}
      display="flex"
      flexDirection="column"
      justifyItems="center"
      sx={{
        [theme.breakpoints.down("md")]: {
          ml: 0,
        },
      }}
    >
      {item?.childs?.map((child, index) => {
        const image =
          (child?.plans && child?.plans[0]?.file) ||
          (child?.files && child?.files[0]?.file) ||
          null;

        const names =
          process.env.SECONDARY_MARKET_UUID === item?.uuid &&
          [
            child?.city_name ? child?.city_name : null,
            child?.district_name ? child?.district_name : null,
          ]
            .filter((el) => el !== null)
            .join(", ");
        return (
          <Box key={child?.uuid}>
            <Hidden mdUp>
              <Box
                mx={12}
                pb={index > 0 && 4}
                borderTop={index > 0 && `1px solid ${theme.palette.grey[400]}`}
              />
            </Hidden>
            <FormControlLabel
              key={child?.name}
              sx={{
                mb: 3,
                width: "100%",
              }}
              label={
                <Box
                  display="flex"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    [theme.breakpoints.down("md")]: {
                      flexDirection: "column",
                      alignItems: "flex-start",
                      mb: 1,
                    },
                  }}
                >
                  <Box
                    display="flex"
                    sx={{
                      cursor: "default",

                      [theme.breakpoints.down("md")]: {
                        flexDirection: "column",
                        width: "100%",

                        justifyContent: "space-between",
                        // alignItems: "center",
                        // flexDirection: "column",
                        // alignItems: "flex-start",
                      },
                    }}
                  >
                    {image && (
                      <>
                        <Hidden mdUp>
                          <DisplayImage
                            width={"100%"}
                            height={"150px"}
                            image={image}
                            openSiglePlanModal={openSiglePlanModal}
                          />
                        </Hidden>
                        <Hidden mdDown>
                          <DisplayImage
                            image={image}
                            openSiglePlanModal={openSiglePlanModal}
                          />
                        </Hidden>
                      </>
                    )}
                    <Box
                      ml={image ? 4 : 0}
                      display="flex"
                      flexDirection="column"
                      justifyContent="center"
                      sx={{
                        [theme.breakpoints.down("md")]: {
                          // width: "70%",
                          ml: 0,
                          mt: 1,
                        },
                      }}
                    >
                      <Box
                        width="max-content"
                        sx={{
                          borderBottom: "1px solid transparent",
                          transition: "0.1s linear",
                          "&:hover": {
                            borderBottom: `1px solid ${theme.palette.secondary.main}`,
                          },
                        }}
                      >
                        <Link href={`/flat/${child?.uuid}`}>
                          <Typography
                            onClick={() => {
                              openComparisonModal(false);
                            }}
                            width="max-content"
                            sx={{ cursor: "pointer" }}
                          >
                            {child?.name}
                            {names && `, ${names}`}
                          </Typography>
                        </Link>
                      </Box>
                      <Box
                        display="flex"
                        alignItems="flex-end"
                        sx={{
                          [theme.breakpoints.down("md")]: {
                            display: "grid",
                            // flexDirection: "column",
                            // alignItems: "flex-start",
                            gridTemplateColumns: "50% 50%",
                            mb: 1,
                            fontSize: "0.9em",
                          },
                        }}
                      >
                        <Box>
                          <Typography fontWeight="bold" variant="span">
                            Cena:
                          </Typography>{" "}
                          {regexStringToNumberPrice(child?.price)} PLN{" "}
                        </Box>
                        <Box>
                          <Typography
                            pl={2}
                            fontWeight="bold"
                            variant="span"
                            sx={{
                              [theme.breakpoints.down("md")]: {
                                pl: 0,
                              },
                            }}
                          >
                            Pokoje:
                          </Typography>{" "}
                          {child?.rooms}{" "}
                        </Box>
                        <Box>
                          <Typography
                            pl={2}
                            fontWeight="bold"
                            variant="span"
                            sx={{
                              [theme.breakpoints.down("md")]: {
                                pl: 0,
                              },
                            }}
                          >
                            Cena m<sup>2</sup>:
                          </Typography>{" "}
                          {regexStringToNumberPrice(child?.pricemkw)} PLN{" "}
                        </Box>
                        <Box>
                          <Typography
                            pl={2}
                            fontWeight="bold"
                            variant="span"
                            sx={{
                              [theme.breakpoints.down("md")]: {
                                pl: 0,
                              },
                            }}
                          >
                            Metraż:
                          </Typography>{" "}
                          {child?.area}m<sup>2</sup>{" "}
                        </Box>

                        <Box>
                          <Typography
                            pl={2}
                            fontWeight="bold"
                            variant="span"
                            sx={{
                              [theme.breakpoints.down("md")]: {
                                pl: 0,
                              },
                            }}
                          >
                            Pietro:
                          </Typography>{" "}
                          {child?.floor}{" "}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center"
                    ml={4}
                    sx={{
                      [theme.breakpoints.down("md")]: {
                        ml: 0,
                        width: "100%",
                      },
                    }}
                  >
                    <Hidden mdDown>
                      {image && (
                        <Box
                          display="flex"
                          alignItems="center"
                          onClick={() => {
                            openSiglePlanModal({ image, investment: false });
                          }}
                        >
                          <PlanSVG />
                        </Box>
                      )}
                    </Hidden>
                    <Button
                      variant="outlined"
                      onClick={() =>
                        deleteSingleItem(
                          "realestate",
                          child?.investment_uuid,
                          child?.uuid
                        )
                      }
                      sx={{
                        width: "max-content",
                        height: "max-content",
                        padding: "12px 40px",
                        [theme.breakpoints.down("md")]: {
                          width: "100%",
                        },
                      }}
                    >
                      Usuń z ulubionych
                    </Button>
                  </Box>
                </Box>
              }
              control={
                <Hidden mdDown>
                  <Checkbox
                    checked={checkCheckbox(child.uuid)}
                    onChange={(e) => handleChange2(e, child)}
                  />
                </Hidden>
              }
            />
          </Box>
        );
      })}
    </Box>
  );

  const itemElement = React.useMemo(() => {
    const arr = [];
    if (item?.area_from && item?.area_to)
      arr.push({
        label: "Metraż",
        value: (
          <>
            <Typography component="span" fontWeight="bold">
              od:
            </Typography>{" "}
            {item?.area_from}m<sup>2</sup>{" "}
            <Typography component="span" fontWeight="bold">
              do:
            </Typography>{" "}
            {item?.area_to}m<sup>2</sup>
          </>
        ),
      });

    if (item?.rooms_from && item?.rooms_to)
      arr.push({
        label: "Pokoje",
        value: (
          <>
            <Typography component="span" fontWeight="bold">
              od:
            </Typography>{" "}
            {item?.rooms_from}{" "}
            <Typography component="span" fontWeight="bold">
              do:
            </Typography>{" "}
            {item?.rooms_to}
          </>
        ),
      });
    if (item?.price_from) {
      arr.push({
        label: "Cena",
        value: (
          <>
            <Typography component="span" fontWeight="bold">
              od:
            </Typography>{" "}
            {regexStringToNumberPrice(item?.price_from)} PLN
          </>
        ),
      });
    }
    return arr;
  }, [item]);

  const image =
    item?.image?.length > 0 && item?.image[0]?.file
      ? item?.image[0]?.file
      : null;

  return (
    <Box
      mt={3}
      // border="1px solid red"

      my={2}
      p={4}
      pb={0}
      sx={{
        [theme.breakpoints.down("md")]: {
          border: `1px solid ${theme.palette.grey[300]}`,
          backgroundColor: "white",
        },
      }}
    >
      <FormControlLabel
        sx={{
          width: "100%",
        }}
        label={
          <Box
            display="flex"
            flexDirection="row"
            alignItems="center"
            width="100%"
            mb={2}
            sx={{
              [theme.breakpoints.down("md")]: {
                flexDirection: "column",
                alignItems: "flex-start",
              },
            }}
          >
            {/* <Box mr={3} style={{ width: 80, height: 60 }}> */}
            <Box
              mr={3}
              sx={{
                [theme.breakpoints.down("md")]: {
                  width: "100%",
                },
              }}
            >
              {image && item?.uuid !== process.env.SECONDARY_MARKET_UUID && (
                <>
                  <Hidden mdDown>
                    <DisplayImage
                      image={image}
                      openSiglePlanModal={openSiglePlanModal}
                      investment
                    />
                  </Hidden>{" "}
                  <Hidden mdUp>
                    <DisplayImage
                      width="100%"
                      height="150px"
                      image={image}
                      openSiglePlanModal={openSiglePlanModal}
                      investmentMobile
                    />
                  </Hidden>
                </>
              )}
            </Box>
            <Box
              width="100%"
              display="flex"
              justifyContent="space-between"
              sx={{
                [theme.breakpoints.down("md")]: {
                  flexDirection: "column",
                },
              }}
            >
              <Box>
                <Box
                  width="max-content"
                  sx={{
                    borderBottom: "1px solid transparent",
                    transition: "0.1s linear",
                    "&:hover": {
                      borderBottom: `1px solid ${theme.palette.secondary.main}`,
                    },
                  }}
                >
                  <Link
                    href={
                      item?.uuid !== process.env.SECONDARY_MARKET_UUID
                        ? `/investment/${getCityAndDistrict(
                            item?.cityToRedirect,
                            item?.districtToRedirect
                          )}/${item?.uuid}`
                        : "/investments?market=secondary"
                    }
                  >
                    <Typography
                      onClick={(e) => {
                        openComparisonModal(false);
                      }}
                      fontWeight="bold"
                      width="max-content"
                      sx={{
                        cursor: "pointer",
                        [theme.breakpoints.down("md")]: {
                          mt: 1,
                        },
                      }}
                    >
                      {item?.name}
                      {item?.uuid !== process.env.SECONDARY_MARKET_UUID &&
                        item?.city &&
                        `, ${item?.city}`}
                    </Typography>
                  </Link>
                </Box>

                {item?.childs?.length === 0 && (
                  <Box
                    display="flex"
                    alignItems="flex-end"
                    sx={{
                      [theme.breakpoints.down("md")]: {
                        flexDirection: "column",
                        alignItems: "flex-start",
                        mb: 1,
                      },
                    }}
                  >
                    {itemElement?.map(({ label, value }) => {
                      return (
                        <TextBox key={label} value={value} label={label} />
                      );
                    })}
                  </Box>
                )}
              </Box>
              {item?.childs?.length === 0 && (
                <Button
                  variant="outlined"
                  onClick={() => deleteSingleItem("investment", item?.uuid)}
                  sx={{
                    height: "max-content",
                    padding: "12px 40px",

                    [theme.breakpoints.up("md")]: {
                      width: "max-content !important",
                    },
                    [theme.breakpoints.down("md")]: {
                      width: "100%",
                    },
                  }}
                >
                  Usuń z ulubionych
                </Button>
              )}
            </Box>
          </Box>
        }
        control={
          <Hidden mdDown>
            <Checkbox
              checked={checkedGroup ? true : false}
              indeterminate={
                checked?.length > 0 && checked?.length < item?.childs?.length
              }
              onChange={handleChange1}
            />
          </Hidden>
        }
      />
      {children}
    </Box>
  );
};

export default CheckGroup;
