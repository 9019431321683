import axios from "utils/axios";

const RealestatesServices = {
  getRealestate: async (id) => {
    return await axios.get(`realestate-view/${id}`).then((res) => res);
  },
  getRealestates: async (limit = 9999, paramsInvestment = {}) => {
    return await axios
      .get(`realestate-index`, {
        params: {
          limit: limit,
          with_existing_properties: 1,
          with_name_as_title_for_secondary_market: 1,
          ...paramsInvestment,
        },
      })
      .then((res) => res);
  },

  getRealestatesInvestment: async (
    investment_uuid,
    limit = 4,
    page,
    paramsInvestment = {}
  ) => {
    const realestateTypes = (elements) => {
      if (!elements) return null;
      const arr = [];

      elements?.forEach((el) => {
        if (el === "other") {
          arr?.push("2");
          arr?.push("11");
        } else {
          arr.push(el);
        }
      });

      return arr;
    };
    realestateTypes(paramsInvestment?.realestate_type_id);

    return await axios
      .get(`/realestate-index`, {
        params: {
          investment_uuid,
          limit,
          with_existing_properties: 1,
          with_name_as_title_for_secondary_market: 1,
          page,
          ...paramsInvestment,
          price_from: paramsInvestment?.price_from
            ? paramsInvestment?.price_from * 1000
            : null,
          price_to: paramsInvestment?.price_to
            ? paramsInvestment?.price_to * 1000
            : null,
          pricemkw_from: paramsInvestment?.pricemkw_from
            ? paramsInvestment?.pricemkw_from * 1000
            : null,
          pricemkw_to: paramsInvestment?.pricemkw_to
            ? paramsInvestment?.pricemkw_to * 1000
            : null,
          realestate_type_id: realestateTypes(
            paramsInvestment?.realestate_type_id
          ),
        },
      })
      .then((res) => {
        return res;
      });
  },
  getRealestatesWithFilters: async (limit = 1, query) => {
    return await axios
      .get(`realestate-index`, {
        params: {
          limit,
          with_existing_properties: 1,
          with_name_as_title_for_secondary_market: 1,
          ...query,
          price_from: query?.price_from ? query?.price_from * 1000 : null,
          price_to: query?.price_to ? query?.price_to * 1000 : null,
          pricemkw_from: query?.pricemkw_from
            ? query?.pricemkw_from * 1000
            : null,
          pricemkw_to: query?.pricemkw_to ? query?.pricemkw_to * 1000 : null,
          district_id:
            query?.district_id &&
            query?.district_id?.filter((el) => el && el !== "0")?.length > 0
              ? query?.district_id?.filter((el) => el && el !== "0")
              : null,
        },
      })
      .then((res) => res);
  },
  getRealestatesInfo: async (limit = 999, realestates) => {
    return await axios
      .get(`realestate-index`, {
        params: {
          limit,
          with_name_as_title_for_secondary_market: 1,
          uuid: realestates,
        },
      })
      .then((res) => res);
  },
};

export default RealestatesServices;
