import {
  ArrowBackIosNew as ArrowBackIos,
  ArrowForwardIos,
  Close,
} from "@mui/icons-material";
import { useEffect, useState } from "react";
import theme from "theme";

const { Box } = require("@mui/material");

const GalerryModal = ({ image, openModal, setOpenModal }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(openModal);
  }, [openModal]);

  if (!image && image.length === 0) return null;
  return (
    <>
      <Box
        position="fixed"
        height="100vh"
        width="100vw"
        top="0"
        left="0"
        bgcolor="rgba(0,0,0,0.7)"
        zIndex={999999}
        display="flex"
        justifyContent="center"
        id="galleryModal"
        onClick={(e) => {
          if (e?.target?.id === "galleryModal") {
            setOpenModal(false);
          }
        }}
        sx={{
          transition: "opacity 0.3s",
          opacity: open ? 1 : 0,
          height: open ? "100vh" : 0,
          transform: `scale(${open ? 1 : 0})`,
        }}
      >
        <Box display="flex" justifyContent="center">
          {open && (
            <img
              key={"galleryModal_" + image[0]?.file}
              style={{
                transition: "0.3s ease-in-out",
                height: "80vh",
                maxWidth: "80vw",
                objectFit: "scale-down",
                opacity: 1,
                position: "absolute",
                top: "10vh",
              }}
              alt={"image"}
              src={`${process.env.BASE_PLAN_URL}${image[0]?.file}`}
            />
          )}
        </Box>

        <Box
          onClick={() => setOpenModal(false)}
          display="flex"
          position="absolute"
          right="100px"
          top="50px"
          borderRadius={50}
          width={60}
          height={60}
          bgcolor="rgba(0,0,0,0.3)"
          alignItems="center"
          justifyContent="center"
          sx={{
            transition: "0.3s",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "rgba(0,0,0,0.4)",
            },
            [theme.breakpoints.down("md")]: {
              top: "auto",
              bottom: 10,
              left: "45%",
              right: "auto",
            },
          }}
        >
          <Close sx={{ fontSize: 40, color: "white" }} />
        </Box>
      </Box>
    </>
  );
};

export default GalerryModal;
