export const markersInvestmets = [
  {
    order: 1,
    value: "only_available_with_us",
    first_line: "Dostępne",
    second_line: "tylko u nas",
  },
  {
    order: 2,
    value: "flats_with_gardens",
    first_line: "Lokale",
    second_line: "z ogródkami",
  },
  {
    order: 3,
    value: "panoramic_windows",
    first_line: "Panoramiczne",
    second_line: "okna",
  },
  {
    order: 4,
    value: "close_to_communication",
    first_line: "Blisko",
    second_line: "komunikacji",
  },
  {
    order: 5,
    value: "green_areas",
    first_line: "Tereny",
    second_line: "zielone",
  },
  {
    order: 6,
    value: "childrens_playground",
    first_line: "Plac zabaw",
    second_line: "dla dzieci",
  },
  {
    order: 7,
    value: "investment_product",
    first_line: "Produkt",
    second_line: "inwestycyjny",
  },
  {
    order: 8,
    value: "protected_estates",
    first_line: "Osiedla",
    second_line: "chronione",
  },
  {
    order: 9,
    value: "close_to_the_center",
    first_line: "Blisko",
    second_line: "centrum",
  },
  {
    order: 10,
    value: "modern_architecture",
    first_line: "Nowoczesna",
    second_line: "architektura",
  },
];
export const markersSecondaryMarketFlat = [
  {
    order: 1,
    value: 5,
    type: "extras",
    first_line: "Z tarasem",
    second_line: "przy mieszkaniu",
  },
  {
    order: 2,
    value: 4,
    type: "extras",
    first_line: "Ogródek",
    second_line: "przy mieszkaniu",
  },
  {
    order: 3,
    value: 9,
    type: "extras",
    first_line: "Mieszkanie",
    second_line: "klimatyzowane",
  },
  {
    order: 4,
    value: 5,
    type: "building_type_id",
    first_line: "Mieszkanie",
    second_line: "w apartamentowcu",
  },
  {
    order: 5,
    value: 0,
    type: "extras",
    first_line: "Mieszkanie",
    second_line: "z balkonem",
  },
  {
    order: 6,
    value: 2,
    type: "extras",
    first_line: "Garaż",
    second_line: "dla mieszkańców",
  },
  {
    order: 7,
    value: 3,
    type: "extras",
    first_line: "Piwnica",
    second_line: "w ofercie",
  },
  {
    order: 8,
    value: 7,
    type: "extras",
    first_line: "Mieszkanie",
    second_line: "dwupoziomowe",
  },
  {
    order: 9,
    value: 1,
    type: "extras",
    first_line: "Z pomieszczeniem",
    second_line: "użytkowym",
  },
  {
    order: 10,
    value: 6,
    type: "extras",
    first_line: "Z windą",
    second_line: "w budynku",
  },
  {
    order: 11,
    value: 0,
    type: "building_state_id",
    first_line: "Gotowe",
    second_line: "do zamieszkania",
  },
  {
    order: 12,
    value: 8,
    type: "extras",
    first_line: "Oddzielna",
    second_line: "kuchnia",
  },
  {
    order: 13,
    value: 0,
    type: "securities",
    first_line: "Rolety",
    second_line: "antywłamaniowe",
  },
  {
    order: 14,
    value: 3,
    type: "securities",
    first_line: "Chroniony",
    second_line: "teren",
  },
  {
    order: 15,
    value: 5,
    type: "securities",
    first_line: "Na zamkniętym",
    second_line: "terenie",
  },
];

export const markersSecondaryMarketHouse = [
  {
    order: 1,
    value: 3,
    type: "extras",
    first_line: "Z basenem",
    second_line: "do dyspozycji",
  },
  {
    order: 2,
    value: 2,
    type: "extras",
    first_line: "Z garażem",
    second_line: "dla mieszkańców",
  },
  {
    order: 3,
    value: 0,
    type: "extras",
    first_line: "Z piwnicą",
    second_line: "w ofercie",
  },
  {
    order: 4,
    value: 6,
    type: "extras",
    first_line: "Klimatyzacja",
    second_line: "w budynku",
  },
  {
    order: 5,
    value: 1,
    type: "vicinities",
    first_line: "Jezioro",
    second_line: "w okolicy",
  },
  {
    order: 6,
    value: 0,
    type: "vicinities",
    first_line: "Las",
    second_line: "w okolicy",
  },
  {
    order: 7,
    value: 3,
    type: "heating_type_id",
    first_line: "Ogrzewany",
    second_line: "pompą ciepła",
  },
  {
    order: 8,
    value: 6,
    type: "media",
    first_line: "Przydomowa",
    second_line: "oczyszczalnia",
  },
  {
    order: 9,
    value: 4,
    type: "heating_type_id",
    first_line: "Ogrzewany",
    second_line: "kolektorem",
  },
  {
    order: 10,
    value: 1,
    type: "garret_type_id",
    first_line: "Z poddaszem",
    second_line: "użytkowym",
  },
  {
    order: 11,
    value: 0,
    type: "building_state_id",
    first_line: "Gotowy",
    second_line: "do zamieszkania",
  },
  {
    order: 12,
    value: 4,
    type: "media",
    first_line: "Dostęp",
    second_line: "do kanalizacji",
  },
  {
    order: 13,
    value: 7,
    type: "extras",
    first_line: "Z umeblowanymi",
    second_line: "wnętrzami",
  },
  {
    order: 14,
    value: 0,
    type: "securities",
    first_line: "Rolety",
    second_line: "antywłamaniowe",
  },
];
