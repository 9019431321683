import { ArrowBackIosNew, Close } from "@mui/icons-material";
import { useStoreActions, useStoreState } from "easy-peasy";
import Image from "next/image";

import theme from "theme";

const { Box } = require("@mui/material");

const SingleImageModal = () => {
  const activeElement = useStoreState(
    (state) => state?.modals?.singlePlanModal
  );
  const openSiglePlanModal = useStoreActions(
    (state) => state.modals.openSiglePlanModal
  );
  if (!activeElement) return null;
  return (
    <>
      <Box
        position="fixed"
        height="100vh"
        width="100vw"
        top="0"
        left="0"
        bgcolor="rgba(0,0,0,0.7)"
        zIndex={999999}
        display="flex"
        justifyContent="center"
        id="galleryModal"
        onClick={(e) => {
          if (e?.target?.id === "galleryModal") {
            openSiglePlanModal(null);
          }
        }}
        sx={{
          transition: "opacity 0.3s",
          opacity: open ? 1 : 0,
          height: open ? "100vh" : 0,
          transform: `scale(${open ? 1 : 0})`,
        }}
      >
        <Box display="flex" justifyContent="center">
          {open && (
            <Box
              sx={{
                transition: "0.3s ease-in-out",
                height: "80vh",
                minWidth: "80vw",
                maxWidth: "80vw",
                opacity: 1,
                position: "absolute",
                top: "10vh",
              }}
            >
              <Image
                unoptimized
                key={"galleryModal_" + activeElement.image}
                alt="investment image"
                layout="fill"
                objectFit="scale-down"
                src={`${
                  activeElement?.investment
                    ? process.env.BASE_IMG_URL
                    : activeElement?.investmentMobile
                    ? process.env.BASE_IMG_URL
                    : process.env.BASE_PLAN_URL
                }${activeElement.image}`}
                quality={75}
              />
            </Box>
          )}
        </Box>

        <Box
          onClick={() => openSiglePlanModal(null)}
          display="flex"
          position="absolute"
          right="100px"
          top="50px"
          borderRadius={50}
          width={60}
          height={60}
          bgcolor="rgba(0,0,0,0.3)"
          alignItems="center"
          justifyContent="center"
          sx={{
            transition: "0.3s",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "rgba(0,0,0,0.4)",
            },
            [theme.breakpoints.down("md")]: {
              top: "auto",
              bottom: 10,
              left: "45%",
              right: "auto",
            },
          }}
        >
          <Close sx={{ fontSize: 40, color: "white" }} />
        </Box>
      </Box>
    </>
  );
};

export default SingleImageModal;
