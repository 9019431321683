import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import dynamic from "next/dynamic";
import style from "./style.module.scss";

import ComparisonModal from "modals/ComparisonModal";
import SingleImageModal from "modals/SingleImageModal";
import CookieInfo from "components/CookieInfo";

import DictionaryServices from "services/DictionaryServices";

import { useStoreActions, useStoreState } from "easy-peasy";
import useEnableNavigation from "hooks/useEnableNavigation";
import ErrorPage from "pages/ErrorPage";

const Header = dynamic(() => import("components/Header"));

const Footer = dynamic(() => import("components/Footer"));
const FooterButtons = dynamic(() => import("components/FooterButtons"));

const Layout = ({ children, footerSmall, footerHidden, buttons, ...rest }) => {
  const [globalError, setGlobalError] = useState(false);

  const setFirstLogin = useStoreActions((state) => state?.auth?.setFirstLogin);

  const firstLogin = useStoreState((state) => state.auth.firstLogin);
  const errorApi = useStoreState((state) => state.errorsStore?.errorApi);

  const setDictionary = useStoreActions(
    (state) => state.dictionary.setDictionary
  );

  const { dictionaryStore } = useEnableNavigation();

  useEffect(async () => {
    if (!firstLogin) return;

    await DictionaryServices.getDictionary().then((res) => {
      if (res?.status === 200 && res?.data) {
        setDictionary(res?.data);
      }
    });

    setFirstLogin(false);
  }, []);

  useEffect(() => {
    if (errorApi) {
      setGlobalError(true);
    }
  }, [errorApi]);

  return (
    <div className={style.container}>
      <Header {...rest} filtersOptions={dictionaryStore} />
      <main className={style.containerMain}>
        {globalError ? <ErrorPage /> : children}
      </main>
      {buttons && <FooterButtons {...rest} />}
      {!footerHidden && <Footer small={footerSmall} />}
      <ComparisonModal />
      <SingleImageModal />
      <CookieInfo />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
