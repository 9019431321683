import Head from "next/head";
import React, { memo } from "react";
import PropTypes from "prop-types";

const Seo = memo(({ title, description = "Expander" }) => {
  const pageTitle = (name) => (name ? `${name} - Expander` : `Expander`);
  return (
    <Head>
      <title>{pageTitle(title)}</title>
      <meta name="description" content={description} />
      <meta name="og:title" content={pageTitle(title)} />
      <meta name="og:description" content={description} />
      <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      <meta name="apple-mobile-web-app-title" content={pageTitle(title)} />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="mobile-web-app-capable" content="yes" />
      <link rel="manifest" href="/static/manifest.json" />

      <link rel="icon" type="image/x-icon" href="/static/favicon.ico" />
      <meta name="msapplication-TileColor" content="#ffffff" />
      <meta
        name="msapplication-TileImage"
        content="/static/ms-icon-144x144.png"
      />
      <meta name="theme-color" content="#ffffff" />
    </Head>
  );
});
Seo.displayName = "Seo";

Seo.propTypes = {
  title: PropTypes.string,
};

Seo.defaultProps = {
  title: null,
};
export default Seo;
