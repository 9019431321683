import Link from "next/link";
import React from "react";
import styleModule from "./style.module.scss";

const MyLink = ({
  children,
  href,
  as,
  onClick = () => {},
  style,
  ariaLabel,
}) => {
  return (
    <Link
      href={href}
      as={as}
      style={style}
      className={styleModule.linkLinkComponent}
    >
      <a
        onClick={() => {
          onClick();
        }}
        style={style}
        aria-label={ariaLabel && ariaLabel}
      >
        {children}
      </a>
    </Link>
  );
};

export default MyLink;
