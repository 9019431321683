import { useState } from "react";
import { Box, Typography } from "@mui/material";

import Select, { components } from "react-select";
import theme from "theme";
import Button from "components/Button";

const ControlButtons = ({ handleButtonsActions, disabled }) => {
  const [value, setValue] = useState({
    value: "remove",
    label: "Usuń z ulubionych",
  });
  const onChange = (e) => {
    setValue(e);
  };
  return (
    <Box
      display="flex"
      sx={{
        [theme.breakpoints.down("md")]: {
          mt: 1,
        },
      }}
    >
      <Select
        id="TypeMarketSelectSearchBox"
        instanceId="TypeMarketSelectSearchBox"
        options={[{ value: "remove", label: "Usuń z ulubionych" }]}
        value={value}
        onChange={onChange}
        placeholder="Wybierz akcję"
        styles={{
          control: (styles) => ({
            ...styles,
            borderRadius: 0,
            height: "100%",
            width: 230,
            fontSize: 16,
          }),
          placeholder: (styles) => ({
            ...styles,
            color: theme.palette.primary.main,
          }),
          valueContainer: (styles) => ({
            ...styles,
            paddingLeft: "20px",
            color: theme.palette.primary.main,
          }),
          indicatorsContainer: (styles) => ({
            ...styles,
            color: "red !important",
          }),
          indicatorSeparator: (styles) => ({
            ...styles,
            display: "none",
            color: theme.palette.primary.main,
          }),
          menu: (styles) => ({
            ...styles,
            zIndex: 9999,
            color: "black",
          }),
        }}
      />
      <Button
        disabled={disabled}
        onClick={() => handleButtonsActions(value)}
        sx={{ width: "max-content", marginLeft: 2 }}
        color={"secondary"}
      >
        <Typography color="white">Wykonaj</Typography>
      </Button>
    </Box>
  );
};

export default ControlButtons;
