import { useEffect, useState } from "react";
import { Box, Typography, Link } from "@mui/material";
import Button from "components/Button";
import theme from "theme";

const CookieInfo = () => {
  const [display, setDisplay] = useState(false);

  function getCookie(name) {
    let cookie = {};
    document.cookie.split(";").forEach(function (el) {
      let [k, v] = el.split("=");
      cookie[k.trim()] = v;
    });
    return cookie[name];
  }

  function setCookie(name, value, days = 365) {
    var expires = "";
    if (days) {
      var date = new Date();
      date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
      expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
  }

  useEffect(() => {
    const cookieInfoInCookie = getCookie("cookie_accept");

    if (!cookieInfoInCookie) {
      setDisplay(true);
    }
  }, []);

  if (!display) return null;
  return (
    <Box
      position="fixed"
      bottom={0}
      left={0}
      width={"100%"}
      bgcolor="white"
      borderTop="1px solid rgba(66, 68, 90, 0.2)"
      py={4}
      px={8}
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        [theme.breakpoints.down("md")]: {
          flexDirection: "column",
          px: 4,
        },
      }}
    >
      <Typography
        sx={{
          [theme.breakpoints.down("md")]: {
            fontSize: "0.75em",
          },
        }}
      >
        Korzystanie z witryny oznacza zgodę na wykorzystanie plików cookie.
        Niektóre z nich mogą być już zapisane w folderze przeglądarki. Więcej
        informacji możesz znaleźć w{" "}
        <Link
          color="secondary"
          underline="hover"
          href="https://www.expander.pl/polityka-prywatnosci"
          target="_blank"
        >
          polityce prywatności
        </Link>{" "}
        i{" "}
        <Link
          color="secondary"
          underline="hover"
          href="https://www.expander.pl/polityka-cookies/"
          target="_blank"
        >
          cookies
        </Link>
        .
      </Typography>
      <Button
        color="secondary"
        sx={{
          width: "max-content !important",
          marginLeft: 4,
          [theme.breakpoints.down("md")]: {
            marginTop: 4,
          },
        }}
        onClick={() => {
          setCookie("cookie_accept", true);
          setDisplay(false);
        }}
      >
        Zgadzam się
      </Button>
    </Box>
  );
};

export default CookieInfo;
