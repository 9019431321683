import { useStoreState } from "easy-peasy";

const useEnableNavigation = () => {
  const dictionaryStore = useStoreState((state) => state?.dictionary?.payload);

  const getLink = (name) => {
    const find = dictionaryStore?.realestate_types?.find(
      (el) => el?.name === name
    );

    if (name === "other") return "other";

    if (find) {
      return find?.id;
    }
    return "1";
  };

  const getEnableNavigation = (name, offer_kind = "primary_market") => {
    const find = dictionaryStore?.realestate_types?.find(
      (el) => el?.name === name
    );

    if (find) {
      return find[offer_kind];
    }
    return "0";
  };

  return { getLink, getEnableNavigation, dictionaryStore };
};

export default useEnableNavigation;
