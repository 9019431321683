import cs from "classnames";
import { Box } from "@mui/material";
import theme from "theme";

export const CloudSVG = ({ size = 56 }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width={size}
      height={size}
    >
      <g transform="matrix(1, 0, 0, 1, -0.729723, 0.689119)">
        <g
          transform="matrix(0.111023, 0, 0, -0.111023, -10.014132, 65.797401)"
          fill="#000000"
          stroke="none"
        >
          <path
            fill="rgb(207, 19, 19)"
            d="M 338 171 C 440.04 170.998 490.478 166.936 498.707 158.707 C 503.155 154.259 505.798 152.25 507.375 152.25 C 507.64 152.25 507.713 152.274 507.939 152.444 C 508.159 152.646 508.28 152.816 508.449 153.277 C 508.831 154.298 509 155.81 509 158 C 509 159.981 510.07 161.995 511.849 163.573 C 513.58 165.105 516.161 166.482 519.296 167.569 C 525.511 169.726 534.429 170.995 545 171 L 579 171 L 579 469 L 191 469 L 191 171 L 338 171 Z M 231.509 434.467 L 542.077 434.467 L 542.077 207.923 L 231.509 207.923 L 231.509 434.467 Z"
          ></path>
        </g>
        <circle
          fill="rgb(229, 160, 32)"
          cx="17.428"
          cy="17.762"
          r="18.767"
          transform="matrix(0.147646, 0.001941, -0.00197, 0.149821, 29.787161, 26.902544)"
        ></circle>
        <circle
          fill="rgb(229, 160, 32)"
          cx="17.428"
          cy="17.762"
          r="18.767"
          transform="matrix(0.147646, 0.001941, -0.00197, 0.149821, 37.480412, 27.007936)"
        ></circle>
        <circle
          fill="rgb(229, 160, 32)"
          cx="17.428"
          cy="17.762"
          r="18.767"
          transform="matrix(0.147646, 0.001941, -0.00197, 0.149821, 22.060429, 26.792284)"
        ></circle>
      </g>
    </svg>
  );
};

export const KeySVG = ({ size = 38 }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 84.9 128"
    >
      <g
        transform="matrix(0.200004, 0, 0, -0.200004, 361.748108, 344.948303)"
        fill="#de1f24"
        stroke="none"
      >
        <g
          transform="matrix(0.019937, 0, 0, 0.019937, -1808.816772, 1084.713623)"
          fill="#000000"
          stroke="none"
        >
          <path
            d="M10155 32089 c-3054 -145 -5876 -1579 -7804 -3969 -1142 -1415 -1911
-3130 -2206 -4920 -103 -629 -139 -1083 -139 -1760 0 -554 18 -861 80 -1350
270 -2130 1179 -4122 2619 -5740 170 -191 559 -584 765 -773 746 -682 1572
-1246 2482 -1695 l218 -107 0 -1454 0 -1455 -952 -1710 c-523 -941 -989 -1778
-1035 -1860 -46 -82 -83 -157 -81 -166 2 -10 622 -1167 1378 -2573 l1375
-2556 4143 -1 4142 0 0 5888 0 5887 218 107 c910 449 1736 1013 2482 1695 206
189 595 582 765 773 1440 1618 2349 3610 2619 5740 62 489 80 796 80 1350 0
563 -18 865 -80 1355 -350 2747 -1741 5223 -3916 6972 -1069 860 -2340 1521
-3663 1907 -1120 326 -2329 470 -3490 415z m1025 -3449 c365 -29 739 -84 1055
-155 1952 -438 3644 -1679 4658 -3416 1219 -2089 1301 -4684 217 -6850 -583
-1163 -1477 -2154 -2580 -2859 -431 -276 -923 -519 -1400 -693 -96 -35 -457
-155 -802 -267 l-628 -204 0 -5378 0 -5378 -1393 0 -1393 0 -448 832 c-372
691 -446 835 -438 852 5 12 363 657 796 1434 l786 1413 0 3112 0 3113 -627
204 c-346 112 -707 232 -803 267 -1717 625 -3157 1910 -3980 3552 -1099 2194
-999 4824 262 6925 1093 1820 2920 3069 4993 3415 282 47 556 75 915 95 118 7
673 -3 810 -14z"
            fill="#de1f24"
          ></path>
        </g>
      </g>
      <circle
        fill="#e5a020"
        cx="17.428"
        cy="17.762"
        r="18.767"
        transform="matrix(0.999914, 0.013146, -0.013146, 0.999914, 25.527502, 24.571169)"
      ></circle>
    </svg>
  );
};
export const HeartSVG = ({ size = 38, active = false, loading = false }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 64 64"
      className={cs("trasition", active ? "active" : "delete")}
    >
      <g
        transform="matrix(0.088629, 0, 0, -0.088629, -1.470422, 53.083778)"
        fill={loading ? "#ad4949" : "#cf1313"}
      >
        <path
          fill={loading ? "#ad4949" : active ? "#cf1313" : "transparent"}
          d="M 83.675 288.546 C 92.122 260.386 124.037 225.656 237.614 120.526 L 380.29 -11.825 L 380.603 -8.391 L 380.915 -11.825 L 523.591 120.526 C 637.168 225.656 669.083 260.386 677.53 288.546 C 714.138 410.572 593.051 520.394 473.842 474.4 C 457.885 467.83 428.786 449.995 410.013 434.038 C 393.648 420.23 384.806 411.437 380.602 401.436 C 376.399 411.437 367.558 420.23 351.192 434.038 C 332.419 449.995 303.32 467.83 287.363 474.4 C 168.154 520.394 47.067 410.572 83.675 288.546 Z"
        ></path>
        <path d="M 476.979 491.189 C 459.979 484.189 428.979 465.189 408.979 448.189 C 376.979 421.189 371.979 412.189 374.979 380.189 L 377.979 343.189 L 437.979 394.189 C 488.979 437.189 502.979 444.189 540.979 444.189 C 601.979 444.189 642.979 404.189 642.979 342.189 C 642.979 300.189 640.979 297.189 506.979 169.189 C 380.979 48.189 371.979 36.189 374.979 6.189 L 377.979 -26.811 L 529.979 114.189 C 650.979 226.189 684.979 263.189 693.979 293.189 C 732.979 423.189 603.979 540.189 476.979 491.189 Z"></path>
        <path
          d="M 156.979 -15.413 C 139.979 -8.413 108.979 10.587 88.979 27.587 C 56.979 54.587 51.979 63.587 54.979 95.587 L 57.979 132.587 L 117.979 81.587 C 168.979 38.587 182.979 31.587 220.979 31.587 C 281.979 31.587 322.979 71.587 322.979 133.587 C 322.979 175.587 320.979 178.587 186.979 306.587 C 60.979 427.587 51.979 439.587 54.979 469.587 L 57.979 502.587 L 209.979 361.587 C 330.979 249.587 364.979 212.587 373.979 182.587 C 412.979 52.587 283.979 -64.413 156.979 -15.413 Z"
          fill={loading ? "#ad4949" : active ? "#cf1313" : "rgb(229, 160, 32)"}
          transform="matrix(-1, 0, 0, -1, 435.292725, 475.775879)"
        ></path>
      </g>
    </svg>
  );
};

export const FilterIcon = ({ white, size = 32 }) => {
  return (
    <Box
      width={`${size}px`}
      height={`${size}px`}
      display="flex"
      flexDirection="column"
      justifyContent="space-evenly"
    >
      <Box
        width="100%"
        bgcolor={white ? "white" : theme.palette.secondary.main}
        height={white ? "1px" : "4px"}
        position="relative"
      >
        <Box
          className="circleLeft"
          position="absolute"
          left="70%"
          top="50%"
          height="6px"
          width="6px"
          border={`1px solid ${white ? "white" : "rgb(229, 160, 32)"}`}
          borderRadius={50}
          bgcolor={white ? "#bdbdbd" : "rgb(229, 160, 32)"}
          sx={{ transform: "translate(-50%, -50%)", transition: "0.3s" }}
        ></Box>
      </Box>
      <Box
        width="100%"
        bgcolor={white ? "white" : theme.palette.secondary.main}
        height={white ? "1px" : "4px"}
        position="relative"
      >
        <Box
          className="circleRight"
          position="absolute"
          left="30%"
          top="50%"
          height="6px"
          width="6px"
          border={`1px solid ${white ? "white" : "rgb(229, 160, 32)"}`}
          borderRadius={50}
          bgcolor={white ? "#bdbdbd" : "rgb(229, 160, 32)"}
          sx={{ transform: "translate(-50%, -50%)", transition: "0.3s" }}
        ></Box>
      </Box>{" "}
      <Box
        width="100%"
        bgcolor={white ? "white" : theme.palette.secondary.main}
        height={white ? "1px" : "4px"}
        position="relative"
      >
        <Box
          className="circleLeft"
          position="absolute"
          left="70%"
          top="50%"
          height="6px"
          width="6px"
          border={`1px solid ${white ? "white" : "rgb(229, 160, 32)"}`}
          borderRadius={50}
          bgcolor={white ? "#bdbdbd" : "rgb(229, 160, 32)"}
          sx={{ transform: "translate(-50%, -50%)", transition: "0.3s" }}
        ></Box>
      </Box>
    </Box>
  );
};
export const FacebookIcon = ({ size = 36 }) => {
  return (
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium iconSVG"
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 24 24"
      width={size}
      height={size}
      fill="currentColor"
    >
      <path d="M 16 4 L 13.5 4 C 11.567 4 10 5.567 10 7.5 L 10 10 L 8 10 L 8 13 L 10 13 L 10 20 L 13 20 L 13 13 L 16 13 L 16 10 L 13 10 L 13 8 C 13 7.448 13.448 7 14 7 L 16 7 L 16 4 Z"></path>
    </svg>
  );
};
export const PhoneIcon = ({ size = 36 }) => {
  return (
    <svg
      className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium iconSVG"
      focusable="false"
      aria-hidden="true"
      viewBox="0 0 27.28 48.17"
      width={30}
      height={59}
      fill="currentColor"
    >
      <path
        fill="#414042"
        d="M24.13 1.92H3.87a3.51 3.51 0 0 0-3.51 3.5v41.16a3.5 3.5 0 0 0 3.51 3.51h20.26a3.5 3.5 0 0 0 3.5-3.51V5.42a3.51 3.51 0 0 0-3.5-3.5zM9 4h10c.25 0 .46.38.46.84s-.2.85-.46.85H9c-.25 0-.46-.38-.46-.85S8.74 4 9 4zm5 42.58a2.24 2.24 0 1 1 2.23-2.24A2.24 2.24 0 0 1 14 46.62zM25 39H3V7.84h22z"
        transform="translate(-.36 -1.92)"
      />
    </svg>
  );
};
export const PlanSVG = ({ size = 64 }) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 64 64"
      width={size}
      height={size}
    >
      <g>
        <path
          fill="rgba(216, 216, 216, 0)"
          stroke="rgb(212, 39, 41)"
          strokeWidth="3px"
          d="M 17.959 17.553 L 17.796 43.012 L 36.525 42.996 L 36.594 36.704 L 46.362 36.704 L 46.276 17.733"
        ></path>
        <path
          fill="rgb(216, 216, 216)"
          stroke="rgb(243, 163, 30)"
          strokeWidth="3px"
          d="M 16.427 17.041 L 47.782 17.042"
        ></path>
      </g>

      <g>
        <path
          fill="rgba(216, 216, 216, 0)"
          stroke="rgb(212, 39, 41)"
          d="M 13.778 16.273 L 13.748 43.934"
        ></path>
        <path
          fill="rgba(216, 216, 216, 0)"
          stroke="rgb(212, 39, 41)"
          d="M 12.468 42.347 L 13.762 43.753 L 14.946 42.302"
        ></path>
        <path
          fill="rgba(216, 216, 216, 0)"
          stroke="rgb(212, 39, 41)"
          d="M 12.514 17.768 L 13.808 16.362 L 14.992 17.813"
        ></path>
      </g>
      <g>
        <path
          fill="rgba(216, 216, 216, 0)"
          stroke="rgb(212, 39, 41)"
          d="M 13.778 16.273 L 13.748 43.934"
        ></path>
        <path
          fill="rgb(216, 216, 216)"
          stroke="rgb(212, 39, 41)"
          d="M 12.468 42.347 L 13.762 43.753 L 14.946 42.302"
        ></path>
        <path
          fill="rgba(216, 216, 216, 0)"
          stroke="rgb(212, 39, 41)"
          d="M 12.514 17.768 L 13.808 16.362 L 14.992 17.813"
        ></path>
      </g>

      <g transform="matrix(0, 1, -0.758761, 0, 49.987968, 33.514526)">
        <path
          fill="rgba(216, 216, 216, 0)"
          stroke="rgb(212, 39, 41)"
          d="M 13.778 16.273 L 13.748 43.934"
        ></path>
        <path
          fill="rgba(216, 216, 216, 0)"
          stroke="rgb(212, 39, 41)"
          d="M 12.468 42.347 L 13.762 43.753 L 14.946 42.302"
        ></path>
        <path
          fill="rgba(216, 216, 216, 0)"
          stroke="rgb(212, 39, 41)"
          d="M 12.514 17.768 L 13.808 16.362 L 14.992 17.813"
        ></path>
      </g>
    </svg>
  );
};
export const LogoSVG = () => {
  const size = 2.5;
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 644 188"
      width={size * 64.4}
      height={size * 18.8}
    >
      <defs>
        <radialGradient
          gradientUnits="userSpaceOnUse"
          cx="1634.716"
          cy="1342.663"
          r="375.793"
          id="gradient-0"
          gradientTransform="matrix(-1.467554, -2.160237, 1.4101, -0.957951, 2255.401607, 6168.453683)"
        >
          <stop offset="0" stopColor="rgba(249, 224, 1, 1)"></stop>
          <stop offset="0.379" stopColor="rgb(247, 210, 1)"></stop>
          <stop offset="1" stopColor="rgb(230, 89, 0)"></stop>
        </radialGradient>
      </defs>
      <g
        transform="matrix(0.1, 0, 0, -0.1, -17.817513, 240.332199)"
        fill="#000000"
        stroke="none"
      >
        <path
          d="M1944 1853 c-81 -120 -158 -233 -170 -251 l-22 -34 66 -99 c37 -54 69 -98 72 -98 5 0 462 673 468 690 2 5 -56 9 -132 9 l-135 0 -147 -217z"
          fill="#de1f24"
        ></path>
        <path
          d="M4750 1830 l0 -120 -77 6 c-95 8 -191 -14 -243 -54 -19 -15 -48 -54 -64 -85 -26 -51 -29 -71 -34 -175 -8 -211 30 -316 133 -370 49 -25 170 -28 238 -5 31 11 57 16 57 11 0 -4 36 -8 80 -8 l80 0 0 460 0 460 -85 0 -85 0 0 -120z m-37 -266 l37 -7 0 -187 0 -188 -51 -12 c-69 -17 -126 -8 -158 24 -26 26 -26 29 -26 170 0 130 2 146 20 166 34 38 88 48 178 34z"
          fill="#de1f24"
        ></path>
        <path
          d="M911 1705 c-85 -24 -135 -67 -173 -150 -21 -45 -23 -65 -23 -200 0 -136 2 -155 23 -202 29 -62 52 -85 117 -114 63 -28 241 -37 355 -17 l75 13 3 68 3 68 -149 -7 c-204 -9 -240 4 -257 99 l-7 37 222 0 223 0 -5 98 c-9 156 -55 244 -154 289 -69 31 -177 39 -253 18z m173 -145 c31 -11 52 -44 59 -92 l6 -38 -135 0 -134 0 6 28 c12 54 27 83 53 97 30 16 108 19 145 5z"
          fill="#de1f24"
        ></path>
        <path
          d="M2492 1704 c-29 -7 -62 -19 -73 -24 -15 -9 -19 -7 -19 5 0 12 -15 15 -80 15 l-80 0 0 -455 0 -455 85 0 85 0 0 113 0 112 107 0 c66 0 123 5 147 14 55 19 110 73 137 134 21 47 24 66 24 202 0 119 -4 161 -18 201 -43 125 -166 178 -315 138z m130 -174 c21 -23 27 -41 33 -103 10 -102 -7 -204 -38 -235 -28 -28 -90 -38 -161 -28 l-46 7 0 179 c0 202 -4 193 83 209 61 12 98 3 129 -29z"
          fill="#de1f24"
        ></path>
        <path
          d="M3072 1706 c-106 -21 -102 -17 -102 -85 l0 -59 150 2 c171 1 179 -2 187 -76 l5 -45 -119 -5 c-168 -8 -225 -35 -259 -124 -22 -57 -15 -177 12 -223 35 -59 76 -76 184 -76 77 0 104 4 143 22 37 17 47 19 47 7 0 -11 17 -14 80 -14 l80 0 0 263 c-1 327 -9 355 -108 399 -58 26 -202 32 -300 14z m238 -457 l0 -70 -37 -16 c-21 -9 -64 -17 -96 -17 -70 -1 -87 15 -87 81 0 33 6 50 25 68 22 23 32 25 110 25 l85 0 0 -71z"
          fill="#de1f24"
        ></path>
        <path
          d="M3920 1710 c-20 -5 -57 -18 -83 -29 -46 -20 -47 -20 -47 -1 0 18 -7 20 -85 20 l-85 0 0 -335 0 -335 90 0 90 0 0 249 0 249 48 16 c85 28 134 31 162 8 l25 -20 3 -251 3 -251 85 0 84 0 0 263 c0 297 -7 338 -64 382 -52 39 -145 54 -226 35z"
          fill="#de1f24"
        ></path>
        <path
          d="M5263 1710 c-100 -21 -164 -81 -194 -182 -18 -59 -21 -88 -17 -202 3 -117 6 -138 28 -181 55 -107 148 -141 350 -131 69 3 141 9 160 14 l35 8 3 67 3 68 -149 -7 c-172 -8 -213 0 -243 50 -10 17 -19 43 -19 59 l0 27 221 0 222 0 -6 93 c-12 211 -100 313 -279 322 -40 2 -92 0 -115 -5z m166 -154 c31 -17 51 -54 51 -96 l0 -30 -131 0 -131 0 7 38 c8 46 30 80 58 92 34 14 115 11 146 -4z"
          fill="#de1f24"
        ></path>
        <path
          d="M6063 1710 c-23 -5 -55 -16 -72 -24 -37 -20 -41 -20 -41 -1 0 12 -16 15 -85 15 l-85 0 0 -335 0 -335 85 0 85 0 2 247 3 248 60 12 c33 7 81 15 108 18 l47 6 0 80 0 79 -32 -1 c-18 -1 -52 -5 -75 -9z"
          fill="#de1f24"
        ></path>
        <path
          d="M 1889.976 1370.884 C 1889.976 1370.884 1742.817 1165.367 1750.625 1176.228 C 1753.704 1180.511 1855 1011 1855 1011 L 1993 1010 L 2132 1010 L 2113 1038 C 2103 1054 2050 1133 1995 1213 L 1889.976 1370.884 Z"
          fill="#de1f24"
        ></path>
        <path
          d="M 1482.78 1156.264 C 1401.78 1036.264 1324.78 923.264 1312.78 905.264 L 1145.244 676.652 C 1145.244 676.652 1425.78 674.264 1428.78 674.264 C 1433.78 674.264 1890.78 1347.264 1896.78 1364.264 C 1898.78 1369.264 1629.78 1373.264 1629.78 1373.264 L 1482.78 1156.264 Z"
          transform="matrix(-1, 0, 0, -1, 3042.074219, 2047.528015)"
          fill='url("#gradient-0")'
        ></path>
        <path
          d="M 1505.747 1732.531 C 1505.747 1732.531 1213.021 1728.035 1220.829 1738.896 C 1223.908 1743.179 1361.771 1533.647 1418.771 1449.647 L 1470.771 1372.647 L 1608.771 1371.647 L 1747.771 1371.647 L 1728.771 1399.647 C 1718.771 1415.647 1665.771 1494.647 1610.771 1574.647 L 1505.747 1732.531 Z"
          fill="rgb(247, 206, 1)"
          transform="matrix(-1, 0, 0, -1, 3113.866943, 3104.177979)"
        ></path>
      </g>
    </svg>
  );
};
